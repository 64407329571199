@import "colors.scss";

.freida {

  .ama-dropdown.ng-select {

    .ng-select-container {
      border: 0.0625rem solid $freida-gray-25;
      border-radius: $border-radius * 2.8 + 2;
      background-color: $freida-white;

      .ng-arrow-wrapper {
        padding-right: $padding * 2;
        padding-left: $padding * 2;

        .ng-arrow {
          border-color: $freida-blue-90 transparent transparent;
        }
      }

      .ng-value-container {
        text-transform: capitalize;
      }
    }

    &-opened {

      .ng-select-container {
        border-radius: $border-radius * 2.8 + 2 $border-radius * 2.8 + 2 0 0;
        border-color: $freida-blue-90;
        border-bottom: 0;

        :hover {
          border-color: $freida-blue-90;
        }

        .ng-arrow-wrapper .ng-arrow {
          top: -0.125rem;
          border-color: transparent transparent $freida-blue-90;
          border-width: 0 0.3125rem 0.3125rem;
          transform: rotate(0deg);
        }
      }

      .ng-dropdown-panel {
        border: 0.0625rem solid $freida-blue-90;
        border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;
        border-top: 0;

        .ng-dropdown-panel-items.scroll-host {
          border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;
        }

        .ng-select-bottom {
          margin-top: -0.5625rem;
          border-radius: $border-radius * 2.8 + 2 $border-radius * 2.8 + 2 0 0;
        }

        .ng-dropdown-panel-items .ng-option.ng-option-marked {
          background-color: $freida-blue-20;
          color: $freida-gray-40;
        }
      }
    }
  }
}

.ribbon-dropdown.freida .ama-dropdown {

  .ng-select-container {
    border: $border-radius * 0.5 solid transparent;
    border-radius: $border-radius * 3 $border-radius * 3 0 0;
    border-bottom: 0;
    min-width: 7.5rem;
    background: transparent;

    .ng-arrow-wrapper .ng-arrow {
      border-color: $freida-purple transparent transparent;
    }
  }

  .ng-placeholder {
    color: $freida-purple;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .ng-dropdown-panel {
    background-color: $freida-white;
    border: 0;
  }

  .ng-option-label {
    color: $freida-purple;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .ng-option-label:hover {
    text-decoration: underline;
  }

  &.ng-select-opened {

    .ng-select-container {
      background: $freida-white;
      border: 0.3rem solid $freida-purple-5;
      border-bottom: 0;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .ng-dropdown-panel {
      border: 0.3rem solid $freida-purple-5;
      border-radius: 0 0 $border-radius * 3 $border-radius * 3;
      border-top: 0;
      overflow: hidden;
    }

    .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background-color: transparent;
    }
  }
}

.saved-programs__dropdown .ama-dropdown.ng-select {
  width: auto;
}

@include susy-at($breakpoint-sm) {

  .saved-programs__dropdown .ama-dropdown.ng-select {
    width: 11.25rem;
  }
}

/* stylelint-disable */
.freida--contact {

  .ama-dropdown.ng-select {

    .ng-select-container {
      border: 0.0625rem solid $freida-gray-25;
      border-radius: $border-radius * 2.8 + 2;
      background-color: $freida-white;

      .ng-arrow-wrapper {
        padding-right: $padding * 2;

        .ng-arrow {
          border-color: $freida-blue-90 transparent transparent;
        }
      }
    }

    &-opened {

      .ng-select-container {
        border-radius: $border-radius * 2.8 + 2 $border-radius * 2.8 + 2 0 0;
        border-color: $freida-blue-90;
        border-bottom: 0;

        :hover {
          border-color: $freida-blue-90;
        }

        .ng-arrow-wrapper .ng-arrow {
          top: -0.125rem;
          border-color: transparent transparent $freida-blue-90;
          border-width: 0 0.3125rem 0.3125rem;
          transform: rotate(0deg);
        }
      }

      .ng-dropdown-panel {
        border: 0.0625rem solid $freida-blue-90;
        border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;
        border-top: 0;

        .ng-dropdown-panel-items.scroll-host {
          border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;
        }

        .ng-select-bottom {
          margin-top: -0.5625rem;
          border-radius: $border-radius * 2.8 + 2 $border-radius * 2.8 + 2 0 0;
        }

        .ng-dropdown-panel-items .ng-option.ng-option-marked {
          background-color: $freida-blue-20;
          color: $freida-gray-40;
        }
      }
    }
  }
}

// Overrides ADDS no-wrap for dropdown options
.program-maintainer-portal {

  .ama-dropdown {
    width: 100%;
  }

  .ama-dropdown .ng-dropdown-panel-items .ng-option {
    white-space: normal;
  }
}
/* stylelint-enable */
