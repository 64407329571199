// AMA Fonts

html {
  @apply font-myriad text-base;
}

body {
  @apply font-myriad text-base text-ama-black;
}

.title {
  font-size: 2.8125rem;
  font-weight: 600;

  @media ( min-width: 740px ) {
    font-size: 4.5rem;
  }
}

h1 {
  font-size: 2.375rem;
  font-weight: 600;

  @media ( min-width: 740px ) {
    font-size: 2.75rem;
  }
}

h2 {
  font-size: 1.875rem;

  @media ( min-width: 740px ) {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 1.5625rem;
  font-weight: 600;

  @media ( min-width: 740px ) {
    font-size: 2.125rem;
  }
}

h3.bold {
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

h5 {
  font-size: 1.3125rem;
  font-weight: 600;

  @media ( min-width: 740px ) {
    font-size: 0.875rem;
  }
}

p,
%p {
  font-size: 1rem;
}

.large {
  font-size: 1.375rem;

  @media ( min-width: 740px ) {
    font-size: 1.5rem;
  }
}

.large.bold {
  font-weight: 600;
}

small,
.small {
  font-size: 0.875rem;
}

small.bold {
  font-weight: 600;
}

.tiny {
  font-size: 0.75rem;
}

.tiny.bold {
  font-weight: 600;
}

.micro {
  font-size: 0.625rem;
}

strong {
  font-weight: 600;
}

.ama__button {
  font-size: 0.875rem;
  font-weight: 600;
}

a {
  font-size: 0.875rem;
  font-weight: 600;
}

label {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  letter-spacing: 0.0675rem;
  text-transform: uppercase;
}

$micro: 0.625rem;

$bold-font: 600;
