@import "colors.scss";

.freida-toggle {
  width: 9.0625rem;
  margin: $margin + 2 0 $margin * 2.2 auto;

  .ama-toggle-button {
    display: flex;
  }

  .ama-button {
    color: $freida-blue-90;
    border: 0.0625rem solid $freida-gray-15;
    font-weight: 700;

    &:hover {
      cursor: pointer;
      border: solid 0.0625rem $freida-blue-90;
    }

    &--active {
      background: $freida-blue-90;
      border: solid 0.0625rem $freida-blue-90;
      color: $ama-white;
      font-weight: 700;
    }
  }
}
