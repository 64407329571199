.freida-app-search-bar {
  width: 100%;
  margin: 0 6.25rem;

  .search-bar__hint {
    margin-left: $margin * 2.5;

    .search-bar__hint-text {
      color: $freida-gray-40;
    }

    .search-bar__hint-view-all {
      color: $freida-blue-90;
    }
  }
}

.freida-app-search-bar-mobile {

  .ama-typeahead-container {
    margin: $margin * 3.5 $margin * 2.5 0 $margin * 2.5;
  }

  .ama-typeahead-container__search {
    right: $margin * 3.5;
  }

  .search-bar__hint {
    margin-left: $margin * 2.5;

    .search-bar__hint-text {
      color: $freida-gray-40;
    }

    .search-bar__hint-view-all {
      color: $freida-blue-90;
    }
  }
}
