body.modal-open {
  overflow: hidden;
}

.freida-modal {

  .ama-modal {

    &__overlay {
      background: rgba(255, 255, 255, 0.6);
      pointer-events: none;
    }

    &__content {
      border: 0.25rem solid $freida-gray-15;
      border-radius: $border-radius + 7;
      box-shadow: none;
      width: 100%;

      @include susy-at($breakpoint-sm) {
        width: auto;
      }

      padding: $padding * 4.8 $padding $padding * 3.4;
      overflow: hidden;
    }
  }

  .freida-gating-modal {

    .freida-modal-close .ama-modal__close {
      display: none;
    }

    .freida-button {

      button {
        @include susy-at($breakpoint-sm) {
          width: auto;
        }

        width: 100%;
      }
    }
  }
}

.notes-modal {

  .ama-modal {

    &__overlay {
      background: rgba(255, 255, 255, 0.6);
      pointer-events: none;
    }

    &__content {
      border: none;
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      padding: 0;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      overflow-y: auto;
      max-height: 100%;

      @include susy-at($breakpoint-sm) {
        border: 0.25rem solid $freida-gray-15;
        border-radius: $border-radius + 7;
        width: 35.75rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 90%;
      }
    }
  }
}

.specialty-modal,
.saved-searches {

  .ama-modal {
    z-index: 9999;

    &__content {
      width: 100%;
      height: 100vh;
      max-height: 100%;

      @include susy-at($breakpoint-sm) {
        width: suzy-span(8);
        max-height: 31.25rem;
        min-height: 36.75rem;
        max-width: 48.25rem;
      }
    }
  }
}

.saved-searches {
  overflow: auto;
}

.download-modal {

  .ama-modal {

    &__content {
      width: 100%;
      height: 19.125rem;

      @include susy-at($breakpoint-sm) {
        padding: 3rem 2.375rem;
        height: 19.125rem;
        max-width: 35.75rem;
      }
    }
  }
}
