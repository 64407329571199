@import "../../lib/assets/scss/library.scss";
@import "colors.scss";

.no-characteristics {
  @extend small;

  margin-bottom: $margin * 5;

  &__heading {
    font-weight: 600;
    letter-spacing: 0.06875rem;
    line-height: 1.43;
    margin-bottom: $margin * 1.8;
    text-align: left;
    text-transform: uppercase;
  }

  &__body {
    color: $freida-gray-50;
    line-height: 1.43;
  }
}

.data-table {
  @extend small;

  background-color: $ama-white;
  border: none;
  border-collapse: collapse;
  margin-bottom: $margin * 5;
  line-height: 1.43;
  width: 100%;
  table-layout: fixed;

  &__caption {

    &__info {
      @extend small;

      color: $ama-gray-55;
      text-transform: none;
    }

    &__right {
      float: right;
    }

    font-weight: 600;
    letter-spacing: 0.06875rem;
    line-height: 1.43;
    margin-bottom: $margin * 1.8;
    text-transform: uppercase;
    text-align: left;
  }

  &__headers {
    @extend .micro;

    border: none;
    font-weight: $bold-font;
    letter-spacing: 0.0625rem;
    line-height: 1.5;
    text-align: left;
    vertical-align: text-top;
  }

  &__headers:last-child {
    text-align: right;
  }

  &__column-header {
    @extend .tiny;

    border: none;
    font-weight: 600;
    letter-spacing: 0.0625rem;
    line-height: 1.5;
    text-align: left;
    vertical-align: text-top;
  }

  &__column-header:last-child {
    text-align: right;
  }

  &__footer {

    td {
      text-align: left;
      background: $ama-white;
    }

    td:last-child {
      text-align: left;
    }

    ul {
      list-style-type: none;
      white-space: nowrap;
    }
  }

  &__row:nth-of-type(2n + 2) td {
    background-color: $ama-white;
  }

  &__row-item {
    background-color: rgba($color: $freida-gray-15, $alpha: 0.28);
    border: none;
    padding: $padding * 0.7;
    text-align: left;
    vertical-align: text-top;

    &__link {
      color: $freida-blue-90;
      font-weight: 400;
      line-height: 1.43;
    }
  }

  &__row-item:last-child {
    text-align: right;
    vertical-align: middle;

    .not-available {
      color: $freida-gray-25;
      font-style: italic;
    }
  }

  &__row-item-link {
    display: none;

    @include susy-at($breakpoint-xs) {
      display: table-cell;
      padding: $padding * 0.7;
    }
  }

  &__row-item-link-anchor {
    display: none;

    @include susy-at($breakpoint-xs) {
      display: table-cell;
      text-align: right;
      word-break: break-word;

      a {
        text-decoration: none;
      }
    }
  }

  &__row-item-link-mobile {
    padding: $padding * 0.7;
    overflow: hidden;
    text-overflow: ellipsis;

    @include susy-at($breakpoint-xs) {
      display: none;
    }
  }
}

.hide-data-table-mobile {
  display: none;

  @include susy-at($breakpoint-xs) {
    display: table;
  }
}

.data-table-mobile {
  @extend small;

  display: block;
  line-height: 1.43;
  margin-bottom: $margin * 2;
  width: 100%;

  &__heading {
    font-weight: $bold-font;
    letter-spacing: 0.06875rem;
    line-height: 1.43;
    margin-bottom: $margin * 1.8;
    text-align: left;
    text-transform: uppercase;
  }

  &__header {
    padding: $padding * 0.7;
  }

  &__card {
    background-color: rgba($color: $freida-gray-15, $alpha: 0.28);
  }

  &__card:nth-of-type(2n + 2) {
    background: $freida-white;
  }

  &__row {
    display: flex;

    div {
      padding: $padding * 0.7;
      width: 100%;
    }

    &__attr {
      padding-left: $padding * 1.8;
    }

    &__value {
      text-align: right;
    }
  }

  @include susy-at($breakpoint-xs) {
    display: none;
  }
}

// Institutions Details

.clinical-environment {

  .data-table {

    &__row {

      &-item:last-child {

        .not-available {
          color: $freida-black;
          font-style: normal;
        }
      }
    }
  }
}
