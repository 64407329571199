@import "colors.scss";

.freida-textarea {
  @apply w-[29.375rem] h-[10.75rem] font-myriad border border-solid border-freida-gray-25 box-border;;
}

.freida-input,
.freida-textarea,
input[ type = "date" ],
input[ type = "text" ],
input[ type = "tel" ],
input[ type = "email" ] {
  @apply text-base rounded-lg border-solid border border-freida-gray-25;

  &:focus,
  &:active {
    @apply border-ama-blue outline-none;
  }

  *:focus {
    @apply outline-none;
  }
}

.freida-password {
  @apply text-base rounded-lg border-solid border border-freida-gray-60 text-freida-gray-50 p-1;


  &:focus,
  &:active {
    @apply border-ama-blue outline-none;
  }
}

input[ type = "date" ] {
  @apply text-freida-gray-40 text-center;
}

.visually-hidden {
  @apply absolute h-0 w-0 overflow-hidden whitespace-nowrap;
}
