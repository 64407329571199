// Primary Colors
/* stylelint-disable color-no-hex */
$freida-black: black; //black
$freida-white: white; //white

$freida-gray-95: #979797;
$freida-gray-85: #545454;
$freida-gray-80: #636370;
$freida-gray-60: #404040;
$freida-gray-55: #4c4b4d; //dark gray
$freida-gray-50: #8b898c; //medium gray
$freida-gray-40: #717073; // light medium gray
$freida-gray-25: #cac8cc; //gray
$freida-gray-15: #e8e6eb; //light gray
$freida-gray-13: #e1e1e6;
$freida-gray-10: #f8f7fa; //off-white

$freida-blue: #1254b3; //dark blue
$freida-blue-90: #0060e6; //blue
$freida-blue-80: #027dbc; //medium blue
$freida-blue-30: #d9f2fc; //light ADDS blue
$freida-blue-20: #f2f8ff; //light blue
$freida-blue-15: #d9e9ff; //medium blue / text
$freida-blue-10: #eef7ff; //light blue / background
$freida-blue-7: #dcf2ff;

$freida-green: #00a68a; //dark green
$freida-green-70: #00d9b5; //green

$freida-purple: #451a80; //secondary
$freida-purple-90: #512d6d; //dark primary
$freida-purple-80: #7233cc; //primary
$freida-purple-40: #e1ccff; //medium-purple/text
$freida-purple-5: #f7f2ff; //light-purple

$freida-purple-100: #300964; //dark purple

$freida-red: #ca0828; //red
$freida-red-10: #ad0003;
$freida-white-opacity-25: #ffffff25;
/* stylelint-enable color-no-hex */
