/***
  To learn about how to use Susy refer to documentation at
  https://oddbird.net/susy/

  Grid system documentation
  https://oddbird.net/2017/06/13/susy-spread/
**/

// Initalize Susy
$susy: (
  "columns": susy-repeat(12),
  "gutters": 10px,
  "spread": "narrow",
  "container-spread": "narrow"
);

$lg: (
  "columns": susy-repeat(12),
  "gutters": 10px
);

$md: (
  "columns": susy-repeat(6),
  "gutters": 10px
);

$sm: (
  "columns": susy-repeat(6)
);

/** Usage
  nav {
    width: span(3 wide, $large-screens);
  }
**/

$padding: 10px;
$margin: 10px;

.container {
  display: flex;
  width: 100%;
}
