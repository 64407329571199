@import "../../lib/assets/scss/library.scss";
@import "colors.scss";

.global-banner .ama-banner {
  background-color: $ama-purple-85;

  &__button--text {
    margin: 0;
  }
}

.landing .specialty-promo-card .ama-banner,
.transition .transition__video .ama-banner {

  &__button {
    background-color: transparent;
    color: $freida-blue-90;
    text-decoration: underline;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-weight: $bold-font;
  }
}

.landing .ama-banner.ama-banner,
.transition__promo .ama-banner {

  &__banner-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include susy-at($breakpoint-sm) {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &__banner-actions {
    margin-top: $margin * 1.9;

    @include susy-at($breakpoint-sm) {
      margin-top: unset;
    }
  }

  &__button {
    background-color: $freida-blue-90;
    color: $freida-white;
    font-weight: $bold-font;
    /* stylelint-disable declaration-no-important */
    padding: $padding * 1.5 $padding * 3.3 !important;
    /* stylelint-enable */
    border-radius: $border-radius * 6;
  }
}

.transition__promo .ama-banner {

  &__banner-actions {
    max-width: $margin * 20;
  }
}
