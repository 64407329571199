@import "../../lib/assets/scss/library.scss";
@import "colors.scss";

.freida-card {

  .ama-card {
    border-radius: $border-radius + 3;
    padding: $padding $padding + 2 $padding + 2 $padding + 2;

    @include susy-at($breakpoint-md) {
      padding: $padding - 2;
    }

    &--shadow {
      border: solid 0.0625rem $freida-white;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(69, 26, 128, 0.08);

      &:hover {
        border: solid 0.0625rem $freida-blue-15;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &--shallow {

    .ama-card.ama-card--white {
      border-radius: $border-radius + 3;
      padding: $padding $padding + 2 $padding + 2 $padding + 2;
      box-sizing: border-box;
      margin: $margin - 6 0;

      @include susy-at($breakpoint-md) {
        padding: $padding - 2;
      }

      &--shadow {
        border: solid 0.0625rem $freida-white;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(69, 26, 128, 0.08);

        &:hover {
          border: solid 0.0625rem $freida-blue-15;
        }
      }

      &__content {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

.specialty-card {

  .ama-card {
    height: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    border-radius: $border-radius + 3;
    overflow: hidden;

    &__content {
      flex-grow: 1;
    }

    &__image {
      max-height: 34.375rem;
      height: 220px;
      object-fit: cover;
    }

    &__main {
      width: 100%;

      @include susy-at($breakpoint-sm) {
        width: 40%;
      }
    }

    &--secondary {
      background-color: $freida-green;
    }

    @include susy-at($breakpoint-sm) {

      &__image {
        max-height: 34.375rem;
        height: 320px;
        object-fit: cover;
      }
    }
  }
}

.specialty-promo-card {

  .ama-card {
    flex-grow: 1;
    flex-wrap: wrap;
    border-radius: $border-radius + 3;
    overflow: hidden;

    &__content {
      padding-left: 0;
      flex-grow: 1;
    }

    &__main {
      display: none;
    }
  }

  .ama-card--horizontal {

    .ama-card__content {
      padding-left: 0;
      flex-grow: 1;
    }
  }
}

.pre-footer-promo__promos .ama-card--white {
  height: 21.51rem;
  padding: $padding * 3.5 $padding * 2 $padding * 5.5 $padding * 2;

  @include susy-at($breakpoint-sm) {
    height: 21.43rem;
    padding: $padding * 6 $padding * 8;
  }
}

.freida-search-result-card .search-result-card {

  &__aside {
    margin-bottom: $margin * 0.3;

    @include susy-at($breakpoint-md) {
      background-color: $freida-gray-10;
      border-radius: $border-radius - 2;
    }
  }

  &__right-content .ama-tag__container {
    white-space: nowrap;
  }

  &__main {
    padding: 0;

    @include susy-at($breakpoint-md) {
      padding: $padding * 0.7 0 $padding * 0.4 $padding * 0.8;
    }
  }

  &__specialty-name {
    text-transform: uppercase;
    margin-bottom: $margin * 1.2;
    color: $freida-purple-80;
    font-weight: 600;
    word-break: break-word;
    line-height: 0.75rem;
  }

  &__title {
    margin-right: $margin * 1.3;

    @include susy-at($breakpoint-md) {
      margin-right: $margin * 5.5;
    }
  }

  &__footer {
    color: $freida-gray-50;
    margin: $margin * 0.5 0 0 0;
    word-break: break-word;

    @include susy-at($breakpoint-md) {
      margin-top: $margin + 4;
      color: $freida-black;
    }
  }

  &--right__icon {
    font-size: 0.875rem;
  }

  .icon-state--invalid {
    position: relative;
  }

  .icon-state--active {
    background-color: $freida-purple-80;
  }
}

.freida-vacant-position-search-result-card .search-result-card {
  @extend .freida-search-result-card;

  &:hover {
    cursor: pointer;
  }

  &__icon-container {
    display: none;
  }
}

.program-management {

  &__program {

    .ama-card {
      margin-bottom: $margin;
    }

    .ama-card--padded {
      padding: $padding $padding * 1.5 $padding * 1.5 $padding * 1.5;
    }

    &__details {

      .ama-card {
        background-color: $freida-gray-10;
      }
    }
  }

  &__promotion {

    .ama-card--white {
      margin-bottom: $margin * 5;
      padding: $padding * 2.3 $padding * 2.3 $padding * 3.8 $padding * 2.3;
      text-align: center;
    }
  }

  &__vacant-position {

    &--deadline {

      .ama-card--collapsible {
        border: 0.0625rem solid $freida-blue-15;
        margin-bottom: $margin;

        .ama-card__header {
          background-color: $freida-blue-15;
          font-weight: 400;
          padding: $padding * 1.5;
        }

        .ama-card__header__title {
          margin-left: 0;
          display: flex;
          align-items: center;
        }

        .status {
          display: flex;
          align-items: center;
        }

        .status__notifications {
          display: flex;
          margin-right: 2.5rem;
        }

        .status__notifications__message {
          margin-left: 0.625rem;
        }

        .status__change-request {
          font-weight: 600;
        }

        .vacant-position {
          color: $freida-blue;
        }
      }
    }

    .ama-card--collapsible {
      border: 0.0625rem solid $freida-blue-20;
      margin-bottom: $margin;

      .ama-card__header {
        background-color: $freida-blue-20;
        font-weight: 400;
        padding: $padding * 1.5;

        &__title {
          margin-left: 0;
        }
      }
    }
  }

  .ama-card {

    &__content {

      &--open {
        padding: $padding * 2;
        box-sizing: border-box;
      }
    }
  }
}

.freida-card-promotion {

  .ama-card {
    border-radius: $border-radius + 3;
    padding: 0;

    &--shadow {
      border: solid 0.0625rem $freida-white;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(69, 26, 128, 0.08);

      &:hover {
        border: solid 0.0625rem $freida-blue-15;
      }
    }

    &--vertical .ama-card__image {
      border-radius: $border-radius;
      display: flex;
      height: 100%;
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }
  }
}

.transition__card {

  &__primary {
    width: 100%;

    .ama-card {
      background-color: $freida-purple;
    }
  }

  &__secondary {
    width: 100%;

    .ama-card {
      background-color: $freida-green;
    }
  }

  .ama-card--vertical .ama-card__content {
    padding: 0;
  }

  .ama-card {
    padding: $padding * 6;
    height: 100%;
  }
}

.transition__video {

  .ama-card--vertical .ama-card__content {
    padding: 0;
  }
}

.member-benefits {

  .ama-card {
    flex-direction: column;
    margin-bottom: $margin * 2;

    &__content {
      padding-bottom: 2rem;
    }

    &--horizontal {

      .ama-card__content,
      .ama-card__main {
        width: 100%;
      }
    }

    @include susy-at($breakpoint-sm) {
      flex-direction: row;

      &--horizontal {

        .ama-card__content,
        .ama-card__main {
          width: intial;
        }
      }
    }
  }
}
