.featured-videos__video__player {

  .video-container {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(69, 26, 128, 0.25);
    border-radius: $border-radius;
  }

  .video-content {
    display: none;
  }
}

.transition {

  .ama-banner__banner-text {
    display: flex;
  }

  &__video {

    .ama-banner__banner-text {
      display: flex;
      flex-direction: column;
      min-height: 250px;
    }

    .ama-banner__banner-actions {
      display: none;
    }
  }
}

.transition .youtube-icon,
.transition .image { /* stylelint-disable-line */
  height: 267px !important; /* stylelint-disable-line declaration-no-important */
}

.specialty-promo-card .youtube-icon,
.specialty-promo-card .image { /* stylelint-disable-line */
  height: 900px !important; /* stylelint-disable-line declaration-no-important */
}

.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .pre-loader {
  height: initial !important;  /* stylelint-disable-line declaration-no-important */
}
