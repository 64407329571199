.freida-primary-outline {

  .ama-button {
    @extend small;

    background: $freida-white;
    color: $freida-blue-90;
    border: 1px solid $freida-gray-25;
    height: 3.125rem;
    width: 9.5625rem;
    font-weight: 700;
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
      border: 1px solid $freida-blue-90;
      background: $freida-white;
    }

    &--icon {
      font-size: 2em;
    }

    &:disabled {
      border: 1px solid $freida-gray-25;
    }
  }
}

.freida-primary-solid {

  .ama-button {
    @apply text-center;
    @extend small;

    background: $freida-blue-90;
    color: $freida-white;
    border: 1px solid $freida-blue-90;
    height: 3.125rem;
    padding: 0 $padding * 3;
    font-weight: 700;
    display: flex;

    &:hover {
      cursor: pointer;
      border: 1px solid $freida-blue;
      background: $freida-blue;
    }

    &--icon {
      font-size: 2em;
    }

    &:disabled {
      border: 1px solid $freida-gray-25;
    }
  }
}

.freida-button .ama-button {
  @extend small;

  background: $freida-blue-90;
  color: $freida-white;
  border: 0.0625rem solid $freida-blue-90;
  height: 3.125rem;
  padding: 0 $padding * 3.5;
  font-weight: 700;
  display: inline-flex;
  width: 15em;
  justify-content: center;

  &:disabled {
    background: $freida-gray-25;
    border: 0.0625rem solid $freida-gray-25;

    &:hover {
      background: $freida-gray-25;
      border: 0.0625rem solid $freida-gray-25;
    }
  }

  &:hover {
    cursor: pointer;
    border: 0.0625rem solid $freida-blue;
    background: $freida-blue;
  }
}

.freida-tools .ama-button.type--icon.ama-button--theme-reset {
  background: $freida-purple-80;
  color: $freida-white;
  border: 0.0625rem solid $freida-purple-80;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius * 10;

  &:hover {
    cursor: pointer;
    border: 0.0625rem solid $freida-purple;
    background: $freida-purple;
  }
}

.ribbon-dashboard {

  .ama-button--theme-primary {
    padding: $padding $padding * 1.2;
    font-size: 0.875rem;
    font-weight: 600;
    background: $freida-purple;
    color: $freida-white;

    &:hover {
      cursor: pointer;
      border: 1px solid $freida-purple;
    }

    .ama-button--text,
    .ama-button--spacer {
      display: none;
      margin-right: $margin * 0.5;
    }

    @include susy-at($breakpoint-sm) {
      padding: $padding $padding * 2;
      display: flex;

      .ama-button--text,
      .ama-button--spacer {
        display: inline-flex;
      }
    }

    &:disabled {
      border: 1px solid $freida-gray-25;
    }
  }
}

.freida-primary {

  &--solid {

    .ama-button.ama-button--theme-primary {
      background: $freida-blue-90;
      color: $freida-white;

      @extend small;

      border: 0.0625rem solid $freida-blue-90;
      height: 3.125rem;
      padding: 0 $padding * 3.5;
      font-weight: 700;
      display: flex;

      &:hover {
        cursor: pointer;
        border: 0.0625rem solid $freida-blue;
        background: $freida-blue;
      }

      &--icon {
        font-size: 2em;
      }

      &:disabled {
        border: 1px solid $freida-gray-25;
      }
    }
  }

  &--outline {

    .ama-button.ama-button--rounded {
      background: $freida-white;
      color: $freida-blue-90;

      @extend small;

      border: 0.0625rem solid $freida-gray-25;
      height: 3.125rem;
      min-width: 9.5625rem;
      flex: 1;
      font-weight: 700;
      display: flex;
      justify-content: center;

      &:hover {
        cursor: pointer;
        border: 0.0625rem solid $freida-blue-90;
        background: $freida-white;
      }

      &--icon {
        font-size: 2em;
      }

      &:disabled {
        border: 1px solid $freida-gray-25;
      }
    }
  }

  &--solid--fixed {

    .ama-button.ama-button--rounded.ripple {
      background: $freida-blue-90;
      color: $freida-white;

      @extend small;

      border: 0.0625rem solid $freida-blue-90;
      height: 3.125rem;
      font-weight: 700;
      display: flex;
      justify-content: center;

      &:hover {
        cursor: pointer;
        border: 0.0625rem solid $freida-blue;
        background: $freida-blue;
      }

      &--icon {
        font-size: 2em;
      }

      &:disabled {
        border: 1px solid $freida-gray-25;
      }
    }
  }
}

.contact__sidebar {

  .freida-primary--outline {
    flex: 1;
    width: 100%;

    .ama-button {
      background: $freida-white;
      color: $freida-blue-90;

      @extend small;

      height: 3.125rem;
      padding: 0 $padding * 3.5;
      font-weight: 700;
      display: flex;

      &:hover {
        cursor: pointer;
        border: 0.0625rem solid $freida-blue-90;
        background: $freida-white;
      }

      &--icon {
        font-size: 2em;
      }
    }
  }
}

.program-management__program__actions {

  .freida-primary-solid {

    .ama-button {
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
      height: 3rem;

      &--icon {
        font-size: 1rem;
      }
    }
  }
}

.calculator {

  .ama-button {
    justify-content: center;
    width: 100%;
    border: none;
  }

  .ama-button--text {
    font-size: 0.875rem;
    text-align: center;
    padding: $padding * 1.2 0;
  }
}

.transition__introduction__buttons .freida-primary--solid--fixed .ama-button.ama-button--rounded.ripple {
  width: auto;
}

.hero__content {

  &__intro__promo__button .ama-button {
    display: table-cell;
    vertical-align: middle;
  }
}

.member-benefits {

  .ama-button {
    display: flex;
    justify-content: center;
    padding: 0 25px;
    height: 3rem;
    max-width: 15rem;
  }

  .ama-button--text {
    font-size: 0.875rem;
    text-align: center;
    padding: $padding * 1.2 0;
  }
}
