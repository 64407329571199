* {
  @apply box-border;
}

body {
  @apply m-0 p-0;
}

.container {
  @apply flex flex-col max-w-7xl w-full mx-auto;
}

.row {
  @apply flex;
}

.col {
  @apply flex flex-col;
}

.spacer {
  @apply flex-1;
}
