@import "colors.scss";

.ama__tooltip {
  position: absolute;
  display: inline-block;
  z-index: 1000;
  background: $ama-white;
  border: 0.125rem solid $ama-green;
  border-radius: $border-radius;
  transition: opacity 500ms;
  text-align: left;
  padding: 0.6rem;
  width: 100rem;

  &__icon {
    position: absolute;
    right: 0.825rem;
    top: 0.2rem;
    bottom: 0.8rem;
    cursor: pointer;
    opacity: 0.4;
    background: none;
    border: none;
    transition: opacity 0.2s ease;
    height: 0.875rem;
    width: 0.875rem;
    color: $ama-gray-50;
    padding-left: 8rem;
  }

  &__icon:hover {
    cursor: pointer;
  }

  &__link {
    color: $freida-blue;
    text-decoration: underline;
    margin-left: $margin * 0.3;
  }

  &__link:hover {
    cursor: pointer;
  }

  &:after,
  &:before {
    content: " ";
    height: 0;
    width: 0;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(121, 183, 213, 0);
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
  }

  &--top {

    &:after,
    &:before {
      top: 100%;
      left: 50%;
    }

    &:after {
      border-top-color: $ama-white;
      margin-left: -0.625rem;
      border-width: 0.625rem;
    }

    &:before {
      border-top-color: $ama-green;
      margin-left: -0.75rem;
      border-width: 0.75rem;
    }
  }

  &--bottom {

    &:after,
    &:before {
      border: solid transparent;
      bottom: 100%;
      left: 50%;
    }

    &:after {
      border-bottom-color: $ama-white;
      margin-left: -0.5625rem;
      border-width: 9px;
    }

    &:before {
      border-bottom-color: $ama-green;
      margin-left: -0.75rem;
      border-width: 0.75rem;
    }
  }

  &--left {

    &:after,
    &:before {
      left: 100%;
      top: 50%;
    }

    &:after {
      border-left-color: $ama-white;
      border-width: 0.5625rem;
      margin-top: -0.5625rem;
    }

    &:before {
      border-left-color: $ama-green;
      border-width: 0.75rem;
      margin-top: -0.75rem;
    }
  }

  &--right {

    &:after,
    &:before {
      right: 100%;
      top: 50%;
    }

    &:after {
      border-right-color: $ama-white;
      border-width: 0.5625rem;
      margin-top: -0.5625rem;
    }

    &:before {
      border-right-color: $ama-green;
      border-width: 0.75rem;
      margin-top: -0.75rem;
    }
  }

  &--right-center {

    &:after,
    &:before {
      right: 100%;
      top: 50%;
    }

    &:after {
      border-right-color: $ama-white;
      border-width: 0.5625rem;
      margin-top: -0.5625rem;
    }

    &:before {
      border-right-color: $ama-green;
      border-width: 0.75rem;
      margin-top: -0.75rem;
    }
  }

  &--show {
    opacity: 1;
  }
}
