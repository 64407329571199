.freida-filters-chips {

  .ama-chip__container {

    max-width: 31.25em;
    min-height: 1.75rem;
    max-height: 3.25rem;
    padding: 0 $padding;
    display: flex;
    align-content: center;
    justify-content: center;
    height: auto;
  }

  .frieda-filters-regchips {
    display: inline-block;
    margin: 0 $margin - 7 $margin 0;

    .ama-chip--secondary {
      background-color: $freida-blue-90;
    }
  }

  .frieda-filters-specchips {
    display: inline-block;
    margin: 0 $margin - 7 $margin 0;

    .ama-chip--secondary {
      background-color: $freida-blue;
    }
  }

  .hidden {
    display: none;
    margin: 0;
  }
}
