.agm-map-container-inner {
  border: 0.0625rem solid $freida-gray-10;
  border-radius: $border-radius + 3;
}

.gm-style-iw-a {
  display: none;

  @include susy-at($breakpoint-xs) {
    display: block;
  }
}

.gm-style .gm-style-iw-c {
  overflow: visible;
}

.agm-info-window-content {
  overflow: visible;
  min-height: 4rem;
}

 /* stylelint-disable */
//  This is put into place because Google Map API forces a scrollbar on the infowindow.
//  Which causes the tooltips for AMA Members to join to get cutoff
.gm-style-iw-d {
  overflow: visible !important;
}

.institutions .gm-style-iw-d {
  position: relative;
  max-width: 90%;
  top: -25px;
  left: 0;
}

.medical-school-programs .gm-style-iw-d,
.vacant-position .gm-style-iw-d {
  max-width: 82%;
  padding: 10px 0;
  position: relative;
  top: 0;
  left: 0;
}

 /* stylelint-enable */
