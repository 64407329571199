.freida-pagination {

  .ama-pagination {
    margin: $margin * 6 0;

    &__arrow {

      &:hover {
        background-color: $freida-blue-15;
      }
    }

    &__button {
      color: $freida-blue;

      &:hover {
        background-color: $freida-blue-15;
      }

      &--primary--active {
        color: $freida-white;
        background-color: $freida-blue;
      }
    }
  }
}
