/*********************
BREAKPOINTS
*********************/

/* Usage
  @include susy-at($breakpoint-sm) {
    // this block establishes a new breakpoint,
    // and any code in this block will use the $medium settings…
  }
*/

$breakpoint-xs: (
  "min-width": 37.5em,
  "columns": susy-repeat(3),
);

$breakpoint-sm: (
  "min-width": 50em,
  "columns": susy-repeat(6),
);

$breakpoint-md: (
  "min-width": 64em,
  "columns": susy-repeat(12),
);

$breakpoint-lg: (
  "min-width": 70em,
  "columns": susy-repeat(12),
);

@mixin susy-use($config) {
  //  parse and normalize any shorthand arguments
  $config: susy-compile($config);

  // record the global settings -
  // and update the global variable with our new settings
  $global: $susy;
  $susy: map-merge($susy, $config) !global;

  // any content inside this mixin
  // will use the local settings
  @content;

  // return the global variable to its initial value
  $susy: $global !global;
}

@mixin susy-at($config) {
  //  parse and normalize any shorthand arguments
  $config: susy-compile($config);

  // build min-and-max queries
  $min: map-get($config, "min-width");
  $min: if($min, "(min-width: #{$min})", null);
  $max: map-get($config, "max-width");
  $max: if($max, "(max-width: #{$max})", null);

  // combine them if we need both
  $and: if($min and $max, "#{$min} and #{$max}", null);
  // or fall back to the value we need…
  $query: $and or $min or $max;

  // apply the results…
  @media #{$query} {
    @include susy-use($config) {
      @content;
    }
  }
}
