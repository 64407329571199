@import "colors.scss";

.freida-filters {

  .ama-slider {
    margin-left: 10px;

    .noUi {

      &-target {
        background: $freida-blue-15;
        border: 0.0625rem solid $freida-blue-15;
      }

      &-connect {
        background: $freida-blue-90;
        border: 0.0625rem solid $freida-blue-90;
      }

      &-handle {
        width: 1.25rem;
        height: 1.25rem;
        background-color: $freida-blue-90;
        border: solid 0.09375rem $freida-blue-90;
      }
    }
  }
}
