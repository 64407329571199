@use '@angular/material' as mat;
@include mat.core();
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* Imports ADDS Library Styles */
@import 'layout.scss',
        'colors.scss',
        'data-table.scss',
        'dropdown.scss',
        'modal.scss',
        'navigation.scss',
        'tooltip.scss',
        'card.scss',
        'tag.scss',
        'inputs.scss',
        'checkbox.scss',
        'multiselect.scss',
        'pagination.scss',
        'range-slider.scss',
        'toggle.scss',
        'videos.scss',
        'typeahead.scss',
        "button.scss",
        "app-search-bar.scss",
        "chip.scss",
        "map.scss",
        "loading.scss",
        'detail_tabs.scss',
        'banner.scss';

// Use defer class to increase performance
// Can cause scroll issues if used improperly
// https://developer.mozilla.org/en-US/docs/Web/CSS/content-visibility
.defer-paint {
  content-visibility: auto;
}
