@import "colors.scss";

.freida-filters {

  .ama-dropdown {

    .ng-select-container {
      border: 1px solid $freida-blue-15;
      border-radius: $input-radius;
      background-color: $freida-white;

      .ng-value-container .ng-placeholder {
        color: $freida-gray-40;
        display: flex;
      }
    }

    .ng-select-container.ng-has-value .ng-value {
      display: none;
    }

    .ng-arrow-wrapper {
      padding-right: $padding * 2;

      .ng-arrow {
        border-color: $freida-blue-90 transparent transparent;
      }
    }

    &.ng-select-opened .ng-select-container {
      border-radius: $input-radius $input-radius 0 0;
      border-bottom: 0;

      .ng-arrow {
        border-color: transparent transparent $freida-blue-90;
      }
    }

    .ng-dropdown-panel {
      padding: gutter();
      background-color: $ama-white;
      /* stylelint-disable */
      border-radius: 0 0 $input-radius $input-radius!important;
      /* stylelint-enable */

      box-shadow: 0 4px 8px 0 rgba(134, 76, 214, 0.08);
      left: 0;
      box-sizing: border-box;
      position: absolute;
      opacity: 0;
      width: 100%;
      z-index: 1050;
      border: 1px solid $freida-blue-15;
      border-top: 0;

      &-items .ng-option {
        margin: $margin * 0.5 0;
        overflow: visible;
      }

      &-items .ng-option.ng-option-marked {
        background-color: $freida-blue-20;
        color: black;
      }
    }
  }
}
