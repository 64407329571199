//Primary Colors

/* stylelint-disable color-no-hex */

$ama-purple: #46166b; // $purple
$ama-purple-85: #7229d1; // $homepagePurple
$ama-purple-60: #9073a6; //$hoverPurple
$ama-purple-20: #dad0e1; //$bodyTextHoverPurple

$ama-blue: #027dbc; // $blue
$ama-blue-40: #0060e6; // royalBlue
$ama-blue-80: #3597c9; // $hoverBlue
$ama-blue-10: #d9f2fc; // $light-blue

$ama-orange: #ed8b00; // $orange
$ama-orange-80: #f1a233; // $hoverOrange

$ama-black: black;
$ama-gray-75: #333; // $ama-gray-75
$ama-gray-64: #5c5c5c; // $ama-gray-64
$ama-gray-55: #717073; // $ama-gray-64
$ama-gray-50: #7f7f7f; // $ama-gray-50
$ama-gray-20: #cbcbcb; //$ama-gray-20
$ama-gray-15: #e8e6eb; //$ama-gray-15
$ama-gray-7: #ededed;
$ama-white: white;

$ama-red: #e90c26; // $red
$ama-red-90: #d81735; // $hoverRed

$ama-green: #4d8406; // $green

/* stylelint-enable color-no-hex */
