.ama__tooltip {
  max-width: gutter() * 15;
  border-radius: $border-radius + 3;
  font-size: gutter() * 1.2;
  font-weight: 600;
  color: $freida-gray-55;
  border: 0.125rem solid $freida-green-70;
  min-width: 10rem;
  white-space: normal;
}
