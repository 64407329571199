.ama-tag--default {
  background-color: $freida-gray-25;
}

.program-card__details__info {

  .freida {
    width: 100%;
  }

  .freida .ama-tag__container {
    display: block;
    text-align: center;
  }

  @include susy-at($breakpoint-sm) {

    .freida {
      width: auto;
    }

    .freida .ama-tag__container {
      display: inline-flex;
    }
  }
}
