@import "colors.scss";

.freida-typeahead-container {
  z-index: 1;

  &__main {

    input.freida-typeahead-container__main__input {
      border-color: $freida-white;
    }

    input.freida-typeahead-container__main__input:active,
    input.freida-typeahead-container__main__input:focus,
    input.freida-typeahead-container__main__input:focus-within {
      border: none;
      border-radius: 0;
      border-bottom: 0.0625rem solid $freida-gray-15;
    }
  }

  &__search {
    z-index: 1002;
  }

  &__selected {
    display: flex;
    flex-wrap: wrap;
  }

  &__selected > .freida-typeahead-chips > .ama-chip__container.ama-chip--rounded.ama-chip--secondary {
    max-width: none;
    max-height: none;
    height: 1.75rem;
    margin: 0.6rem 0.6rem 0 0;

    .ama-chip__text {
      white-space: nowrap;
    }

    .ama-icon-close {
      margin-right: 0.5rem;
    }
  }

  &__results {

    &__list {
      list-style: none;
      margin: 0;
      padding: $padding 0;
      max-height: 10.625rem;
      overflow-y: scroll;
    }

    &__list > .freida-typeahead-item {
      max-height: none;
      height: 2.375rem;
      list-style: none;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.625rem 0 0.625rem 0.625rem;
      color: $freida-gray-60;
    }

    &__list > .freida-typeahead-item:hover,
    &__list > .freida-typeahead-item-selected {
      background-color: $freida-blue-30;
    }
  }
}

/* stylelint-disable selector-type-no-unknown */

app-autocomplete {
  position: absolute;
  padding: 0 $padding $padding;
  background: $freida-white;
  box-shadow: 0 0.25rem 0.5rem 0 $freida-gray-15;
  top: 100%;
  width: 100%;
  margin-top: $margin * -0.66;
  border-bottom-left-radius: $border-radius * 2;
  border-bottom-right-radius: $border-radius * 2;
}
/* stylelint-enable */
