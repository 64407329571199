@import "susy/sass/susy";
@import "susy/sass/plugins/svg-grid/prefix";
@import "vendor/nouislider.scss";
@import "variables/breakpoints.scss";
@import "variables/grid.scss";
@import "variables/colors.scss";
@import "variables/typography.scss";
@import "variables/borders.scss";
@import "variables/input.scss";
@import "directives/tooltip.scss";
/***
Example of usage of all variable and mixins

.button-primary {
  ------ typography.scss -----

  ------ grid.scss -----
  padding: gutter();

  ------ colors.scss -----
  color: $ama-white;
   background: $ama-blue;

  ------ grid.scss -----
  width: span(3 narrow, $sm);

  ------ breakpoint.scss -----
  @include susy-at($breakpoint-sm) {
    background: $ama-green;
    width: span(3 wide, $sm);
  }

  @include susy-at($breakpoint-md) {
    background: $ama-orange;
    width: span(6 narrow, $md);
  }

  @include susy-at($breakpoint-lg) {
    background: $ama-purple;
    width: span(12 narrow, $lg);
  }
}
**/
