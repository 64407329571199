.freida-loading {
  margin: $margin * 2 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 17rem;
}

.freida-loading-text {
  color: $freida-gray-50;
}

.freida-spinner {
  border: 0.5rem solid $freida-gray-25;
  border-top: 0.5rem solid $freida-blue-90; /* Blue */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
