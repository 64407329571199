@import "colors.scss";

.freida {
  .ama-checkbox {
    &__checkmark-container {
      background-color: $freida-white;
      border: 0.0625rem solid $freida-gray-25;
      margin-right: $margin - 2;
    }

    & input:checked ~ .ama-checkbox__checkmark-container--blue {
      background-color: $freida-blue-90;
      border-color: $freida-blue-90;
    }

    & input:checked ~ .ama-checkbox__checkmark-container--purple {
      background-color: $freida-blue-90;
    }

    &__checkmark:after {
      width: 0.3125rem;
      height: 0.625rem;
      left: 0.375rem;
      top: 0.125rem;
      border-width: 0 1px 1px 0;
    }

    &__label {
      font-weight: 400;
      letter-spacing: normal;
    }
  }
}

.saved-programs__sort-bar--left {
  .ama-checkbox__checkmark-container {
    margin-right: 0;
  }
}

.checkbox-disabled {
  color: $freida-gray-50;
  pointer-events: none;

  .ama-checkbox {

    input[ type = "checkbox" ]:checked {
      pointer-events: all;
    }
  }
}
