@import "../../lib/assets/scss/library.scss";
@import "colors.scss";

.sticky-nav {
  position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
}

.program-nav-expanded {
  @apply block md:flex content-between;

  justify-content: space-between;
}

.specialty-nav-expanded {
  display: flex;
  justify-content: space-between;
}

.program-nav,
.specialty-nav {
  display: flex;
  justify-content: space-between;
  background-color: $freida-white;
  box-shadow: none;
  border-bottom: none;
  flex-direction: column;

  @include susy-at($breakpoint-sm) {
    flex-direction: row;
  }
  
  &:before {
    box-shadow: -0.0625rem 0 0.125rem 0 $freida-gray-15;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0.0625rem;

    @include susy-at($breakpoint-sm) {
      box-shadow: none;
    }
  }

  &:after {
    box-shadow: 0.0625rem 0 0.125rem 0 $freida-gray-15;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 0.0625rem;

    @include susy-at($breakpoint-sm) {
      box-shadow: none;
    }
  }

  @include susy-at($breakpoint-sm) {
    border-bottom: $margin * 0.8 solid $freida-blue-90;
  }

  @media print {
    display: none;
  }
}

.program-sub-nav,
.specialty-sub-nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 10.625rem;

  @include susy-at($breakpoint-sm) {
    flex-direction: row;
    min-height: 65px;
    margin: 0;
  }

  .program-sub-nav__title,
  .specialty-sub-nav__title {
    width: susy-span(4);
    padding-bottom: 0.9375rem;
    font-size: 1.75rem;
    color: $freida-gray-50;

    @include susy-at($breakpoint-sm) {
      font-size: 2.25rem;
      color: $freida-black;
    }
  }

  .program-sub-nav__items,
  .specialty-sub-nav__items {
    display: none;

    @include susy-at($breakpoint-sm) {
      display: flex;
      color: $freida-blue-90;
    }
  }

  .program-sub-nav__item,
  .specialty-sub-nav__item {
    @include susy-at($breakpoint-sm) {
      font-size: 0.75rem;
      padding: $padding * 2 $padding * 2.4;
    }

    @include susy-at($breakpoint-md) {
      font-size: 1rem;
      padding: $padding * 2.4;
    }

    display: flex;
    font-weight: $bold-font;
    align-content: center;
    justify-content: center;
    border-radius: $border-radius + 3 $border-radius + 3 0 0;
    white-space: nowrap;
    flex-wrap: nowrap;
    cursor: pointer;
    outline: none;

    &--print {
      display: none;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .freida .ng-select-container {
    border: 1px solid $freida-gray-25;
    border-radius: $border-radius * 2.8 + 2;

    .ng-arrow {
      border-color: $freida-blue-90 transparent transparent;
    }
  }

  .freida .ng-select-container .ng-dropdown-panel {
    border: 1px solid $freida-gray-25;
    border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;

    .ng-dropdown-panel-items.scroll-host {
      border-radius: 0 0 $border-radius * 2.8 + 2 $border-radius * 2.8 + 2;
    }

    .ng-select-bottom {
      margin-top: -$margin * 0.9;
    }

    .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background-color: $freida-gray-15;
      color: $freida-black;
    }
  }

  &__select {
    display: block;
    margin-bottom: $margin * 2;

    .freida .ng-select-opened {

      .ng-select-container {
        border-radius: $border-radius * 2.8 + 2 $border-radius * 2.8 + 2 0 0;

        .ng-arrow {
          top: -2px;
          border-color: transparent transparent $freida-blue-90;
          border-width: 0 5px 5px;
          transform: rotate(0deg);
        }
      }
    }

    @include susy-at($breakpoint-sm) {
      display: none;
    }
  }

  .active-item {
    background-color: $freida-blue-90;
    color: $freida-white;
  }
}
