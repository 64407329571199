label {

  span {
    margin-bottom: 10px;
    display: block;
    text-transform: uppercase;
    color: $ama-gray-50;
    font-weight: 600;
  }
}

input[ type = "text" ],
input[ type = "number" ],
input[ type = "tel" ],
input[ type = "date" ],
input[ type = "email" ] {
  @extend %p;

  border: 1px solid $ama-gray-7;
  border-radius: $input-radius;
  padding: 10px;

  &:focus,
  &:active {
    border-color: $ama-blue;
    outline: none;
  }

  *:focus {
    outline: none;
  }

  &.error {
    border-color: $ama-red;
  }
}
